import React, { useState, createContext } from "react";

// export const GlobalSpinnerContext = createContext();
export const GlobalContext = createContext();
const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    window_: window,
    user_data: localStorage.getItem("user_data"),
    user_type: localStorage.getItem("user_type"),
    isAdmin: localStorage.getItem("isAdmin"),
    primary_marketplace: localStorage.getItem("primary_marketplace"),
    admin_data: localStorage.getItem("admin_data"),
    selected_sub_user: localStorage.getItem("selected_sub_user"),
    // module_list: localStorage.getItem("module_list")
    //   ? JSON.parse(localStorage.getItem("module_list"))
    //   : [],
  });
  const [sub_user_list, set_sub_user_list] = useState({
    user_list: localStorage.getItem("sub_user_list"),
  });

  return (
    <GlobalContext.Provider
      value={{
        data: commonGlobalVal,
        updateCommonGlobalVal: (rest) => {
          setCommonGlobalVal({ ...commonGlobalVal, ...rest });
        },

        user_list: sub_user_list,
        updateCommonUserListVal: (rest) => {
          set_sub_user_list({ ...sub_user_list, ...rest });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;
