import { all } from "axios";
import React, { useState } from "react";
import { Collapse, Popover, Select } from "antd";

const SBCreateAnAdd = (props) => {
  const { all_data, set_all_data } = props;

  const [expandIconPosition, setExpandIconPosition] = useState('end');

  const onPositionChange = (newExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const text = `...`;

  const genExtra = () => (
    <i className="ki-outline ki-check-circle me-3 fs-3 text-success" />
  );

  const items = [
    {
      key: '1',
      label: (
        <div className="d-flex align-items-center">
          {genExtra()}
          <span>Brand Name</span>
        </div>
      ),
      children: <p>{text}</p>,
    },
    {
      key: '2',
      label: (
        <div className="d-flex align-items-center">
          {genExtra()}
          <span>Headline</span>
        </div>
      ),
      children: <p>{text}</p>,
    },
    {
      key: '3',
      label: (
        <div className="d-flex align-items-center">
          {genExtra()}
          <span>Products(3)</span>
        </div>
      ),
      children: <p>{text}</p>,
    },
    {
      key: '4',
      label: (
        <div className="d-flex align-items-center">
          {genExtra()}
          <span>Custom Images</span>
        </div>
      ),
      children: <p>{text}</p>,
    },
  ];

  return (
    <>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className="row gy-5 g-xl-5">
          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Ad Settings</h3>
                </div>

                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className="row mb-0 ">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Ad Name
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control fs-7"
                      placeholder="Enter Ad Name"
                      value={all_data?.advertise_name}
                      onChange={(e) => {
                        set_all_data({ ...all_data, advertise_name: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Creative</h3>
                </div>

                <div className="card-toolbar">
                  <button className="btn btn-light-primary fs-7 fw-bold">
                    Generate images with AI
                  </button>
                </div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className=" row g-5 gx-8">
                  <div className="col-md-5 cus-acc">
                    <div>
                      <Collapse className="bg-white py-3" style={{ borderWidth: 0.5, borderColor: '#f1f1f4' }} defaultActiveKey={[]} onChange={onChange} items={items} expandIconPosition='right' />
                    </div>
                  </div>
                  <div className="col-md-6 mx-auto">
                    <div className="position-relative w-200px">
                      <div className="d-flex align-items-center fw-bold">
                        <div className="text-muted fs-7">Type</div>
                        <Select
                          options={[
                            { label: "Mobile Top of Search", value: "MOBILE" },
                            { label: "Desktop Top of Search", value: "DESKTOP" },
                          ]}
                          value={all_data?.advertise_type}
                          onChange={(e) => {
                            set_all_data({ ...all_data, advertise_type: e });
                          }}
                          size="large"
                          style={{ width: "100%", marginLeft: '10px' }}
                        />
                      </div>
                    </div>
                    <div className="mt-8">
                      <img
                        src="https://ipsf.net/wp-content/uploads/2021/12/dummy-image-square.webp"
                        className="w-100 rounded"
                        style={{ height: "250px", }}
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SBCreateAnAdd;
