// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/rule-book",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-book fs-1"></i>
      </span>
    ),
    label: GetLinks("/rule-book", "Rule Book"),
  },
];
