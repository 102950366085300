import React from "react";
import { Table } from "antd";

const EsfTable = (props) => {
  const {
    columns,
    fixed,
    dataSource,
    rowKey,
    loading,
    pagination,
    scrollX,
    scrollY,
    isCheckBoxRow,
    rowSelection,

    sortFilters = "",
    setSortFilters = () => {},
    setSortOrderColumns = () => {},
    onSortChange,
    ...rest
  } = props;
  const ellipsis = false;

  const PropsFilter = (d) => {
    if (d?.localSort) {
      return {
        sorter: (a, b) => {
          return typeof a?.[d?.dataIndex] === "string"
            ? (a?.[d?.dataIndex] || "")?.localeCompare(b?.[d?.dataIndex] || "")
            : parseFloat(a?.[d?.dataIndex] || 0) -
                parseFloat(b?.[d?.dataIndex] || 0);
        },
      };
    }
    return {
      defaultSortOrder: sortFilters
        ? sortFilters?.startsWith("-")
          ? "ascend"
          : "descend"
        : "",
      sorter: () => {},
    };
  };

  const handleChange = (pagination, __, sorter, e) => {
    const { order, column } = sorter;
    const { current, pageSize } = pagination;

    try {
      if (!column?.localSort) {
        const sort = order
          ? `${order === "ascend" ? "-" : ""}${column?.dataIndex}`
          : "";
        setSortFilters(sort);
        setSortOrderColumns(column?.dataIndex);
        onSortChange(
          sort,
          e?.action === "sort" ? 1 : current,
          pageSize,
          column?.dataIndex
        );
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const columns_ = columns?.map((d) => {
    if (Object.hasOwn(d, "dataIndex") && (d?.sortOn || d?.localSort)) {
      return {
        ...d,
        ...PropsFilter(d),
      };
    }
    return d;
  });

  return (
    <Table
      fixed={fixed}
      dataSource={dataSource?.map((d, i) => {
        return { ...d, key: i };
      })}
      rowKey={rowKey}
      loading={loading}
      pagination={pagination}
      ellipsis={false}
      scrollableTarget="tableContainer"
      rowSelection={
        isCheckBoxRow
          ? {
              type: "checkbox",
              ...rowSelection,
            }
          : ""
      }
      onChange={handleChange}
      {...rest}
      columns={columns_}
    />
  );
};

export default EsfTable;
