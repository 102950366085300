import React from "react";
import NoData from "../no-data";
import { getTableLoading } from "../../config";

const NoDataTable = (props) => {
  const { type, columns } = props;
  return (
    <>
      <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gx-4 gs-4">
        <thead>
          <tr class="fw-bolder text-gray-800 bg-gray-100">
            {columns?.map((d) => {
              return (
                <th style={{ minWidth: d?.title?.length * 12 }}>{d?.title}</th>
              );
            })}
          </tr>
        </thead>
        {type === "loading" && (
          <tbody>{getTableLoading(columns?.length)}</tbody>
        )}
      </table>
      {type === "no-data" && <NoData type="new" />}
    </>
  );
};

export default NoDataTable;
