// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/dashboard",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-element-11 fs-1"></i>
      </span>
    ),
    label: GetLinks("/dashboard", "Dashboard"),
  },
];
