import axios from "axios";
import { ENDPOINT } from "../config";
import { message } from "antd";

// admin = 1
// user = 2
// seller = 3
// sub user = 4  (sub user)

const getToken = () => {
  const usertype = localStorage.getItem("user_type");
  const token_type = localStorage.getItem("token_type");
  const token =
    localStorage.getItem("user_type") == 1 ||
    localStorage.getItem("user_type") == 3 ||
    localStorage.getItem("user_type") == 4
      ? localStorage.getItem("token")
      : localStorage.getItem("user_type") == 2 &&
        localStorage.getItem("token_type") === "main_token"
      ? localStorage.getItem("token")
      : localStorage.getItem("sub_user_token");
  return {
    usertype,
    token_type,
    token,
  };
};

// const getToken = () => localStorage.getItem("token");

// Common function for making API calls
export const MakeApiCall = async (
  url,
  method = "get",
  data = null,
  withBearer = false,
  isFormData = false
) => {
  console.log(getToken(), "-getToken");
  try {
    const headers = {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    };

    if (withBearer) {
      // Assuming you have a way to retrieve the authentication token
      if (getToken()) {
        headers["Authorization"] = `Bearer ${getToken()?.token}`;
      }
    }
    let url_ = ENDPOINT + url;
    const response = await axios({
      method,
      url: url_,
      data: data || null,
      // data: data ? JSON.stringify(data) : null,
      headers,
    });

    return response.data;
  } catch (error) {
    // Handle errors here (e.g., log them or throw a custom error)
    const newData = JSON.stringify(error);
    const finalData = JSON.parse(newData);
    if (finalData?.status == 401) {
      localStorage.clear();
      return window.location.assign("/login");
    } else {
      const errorData = {
        status: false,
        message: "Something went wrong",
      };
      return errorData;
    }
    // throw { message: error?.message };
  }
};
