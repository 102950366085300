import { GetLinks } from "../../../../core";

export default [
  {
    key: "/ketword-management",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-book fs-1"></i>
      </span>
    ),
    label: "Keyword Management",
    children: [
      {
        key: "/keyword-management/keyword-targeting",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks(
          "/keyword-management/keyword-targeting",
          "Keyword Targeting"
        ),
      },
      // {
      //   key: "/keyword-management/keyword",
      //   icon: (
      //     <span className="menu-bullet">
      //       <span className="bullet bullet-dot" />
      //     </span>
      //   ),
      //   label: GetLinks("/keyword-management/keyword", "Keywords"),
      // },
    ],
  },
];
