import { Alert, Checkbox, Col, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function (props) {
  const { show, onHide, campaignList } = props;
  console.log(campaignList, "-campaignList");

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Campaigns List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              height: "350px",
              overflow: "auto",
            }}
          >
            <div className="row">
              {campaignList?.map((d, i) => {
                return (
                  <>
                    <div className="">
                      <label className="fw-bold fs-6">{d}</label>
                    </div>
                    <div className="separator separator-dashed my-3" />
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
