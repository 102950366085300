import React, { useContext, useEffect, useState } from "react";
import SPSteps from "./lib/steps";
import SPStep1 from "./lib/step-1";
import SPStep2 from "./lib/step-2";
import SPStep3 from "./lib/step-3";
import SPStep4 from "./lib/step-4";
import SPStep5 from "./lib/step-5";
import dayjs from "dayjs";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
} from "../../../../../config";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../../commonContext";

const SponsoredProducts = (props) => {
  const { setMode, selected_marketplace, selected_currency_sign } = props;
  const contextValue = useContext(GlobalContext);
  const navigate = useNavigate();
  const [selectedStep, setSelectedStep] = useState("step1");
  const [loading, set_loading] = useState(false);
  const [all_common_hide_show, set_all_common_hide_show] = useState({
    add_items: "search",
    negative_targeting: "NEGATIVE_EXACT",
    manual_keyword_targeting: "suggested",
    manual_product_targeting: "category",
    manual_product_category: "suggested",
    manual_product_individual: "suggested",
    negative_product_targeting: "exclude_brand",
    negative_exclude_product: "search",
    auto_negative_product: "search",
  });

  const [selected_negative_keyword, set_selected_negative_keyword] = useState(
    []
  );
  const [selected_negative_brand_product, set_selected_negative_brand_product] =
    useState([]);
  // ----------------------------------------------------------  Step 1 -----------
  const [campaign_data_list, set_campaign_data_list] = useState({
    campaign_ad_type: "SP",
    name: "",
    targeting_type: "AUTO",
    start_date: dayjs()?.format("YYYY-MM-DD"),
    end_date: null,
    // end_date: dayjs().add(30, "d")?.format("YYYY-MM-DD"),
    daily_budget: "",
    // daily_limit: "",
    portfolio_id: null,
    bidding_strategy: "LEGACY_FOR_SALES",
    top_of_search_placement: 0,
    product_pages_placement: 0,
    rest_of_search_placement: 0,
    state: "ENABLED",
  });
  // const [run_indefinitely_end_time, set_run_indefinitely_end_time] =
  //   useState(false);
  const [auto_targeting_radio, set_auto_targeting_radio] =
    useState("default_bid");
  const [portfolioList, setPortfolioList] = useState([]);

  // ----------------------------------------------------------  Step 2 -----------
  const [selected_adgroup_list, set_selected_adgroup_list] = useState([]);
  const [ad_group_default_bid, set_ad_group_default_bid] = useState(0);
  const [common_default_bid, set_common_default_bid] = useState(0);
  const [ad_group_product_targeting, set_ad_group_product_targeting] = useState(
    []
  );
  const [campaign_bidding_strategy_label, set_campaign_bidding_strategy_label] =
    useState({
      header: "Adjust bidding (down only)",
      content:
        "Lowers your bids in real time when your ad may be less likely to convert to a sale. Any campaign created before October 2018 uses this setting by default.",
    });

  // ----------------------------------------------------------  Step 3 -----------
  const [product_list, set_product_list] = useState([]);
  const [selected_product_list, set_selected_product_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [product_loading, set_product_loading] = useState(false);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search: "",
  });

  // ----------------------------------------------------------  Step 4 -----------
  const [manual_sub_targeting_type, set_manual_sub_targeting_type] =
    useState("keyword_targeting");

  // ----------------------------------------------------------  Step 5 -----------
  const [selected_keyword_list, set_selected_keyword_list] = useState([]);
  const [selected_categroy_list, set_selected_categroy_list] = useState([]);
  const [
    selected_individual_product_list,
    set_selected_individual_product_list,
  ] = useState([]);

  useEffect(() => {
    GetProductList(all_filters);
    GetPortfolioList();
  }, [contextValue?.data?.selected_sub_user]);

  const GetProductList = async (filters) => {
    const response = await MakeApiCall(
      `report/product-list${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      set_product_loading(false);
      set_product_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetPortfolioList = async () => {
    const response = await MakeApiCall(
      `advertising/all-portfolios?marketplace_id=${selected_marketplace}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setPortfolioList(response?.data);
    } else {
      setPortfolioList([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    set_product_loading(true);
    GetProductList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    set_product_loading(true);
    GetProductList(apiObj);
  };

  const UpdateCampaignDataList = (key, value) => {
    const common_data = {
      ...campaign_data_list,
      [key]: value,
    };
    set_campaign_data_list(common_data);
  };

  const CreateCampaign = async (campaignJson) => {
    const response = await MakeApiCall(
      `advertising/create-campaign`,
      `POST`,
      campaignJson,
      true
    );
    if (response?.status) {
      set_loading(false);
      DisplayNotification("success", "Success", response?.message);
      setMode("campaign-list");
    } else {
      set_loading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const MakeAutoCampaignJson = () => {
    const productList = selected_product_list?.map((d) => {
      return { sku: d?.seller_sku, asin: d?.asin1, state: d?.state };
    });
    // const campaignData = {
    //   ...campaign_data_list,
    //   end_date: campaign_data_list?.end_date || null,
    // };
    const newJson = {
      marketplace_id: selected_marketplace,
      campaign_data: campaign_data_list,
      ad_groups_data: [
        {
          name: selected_adgroup_list?.[0],
          default_bid: ad_group_default_bid,
          state: "ENABLED",
          product_ads: productList,
          product_targeting: ad_group_product_targeting?.map((d) => {
            return {
              match_type: d?.targeting_expression_type,
              bid: d?.suggested_bid,
            };
          }),
          keyword_targeting: [],
          negative_keyword_targeting: selected_negative_keyword,
          negative_product_targeting: selected_negative_brand_product?.map(
            (d) => {
              return {
                expression_type: d?.expression_type,
                state: d?.state,
                expression_value: d?.expression_value,
              };
            }
          ),
        },
      ],
    };
    if (auto_targeting_radio === "default_bid") {
      delete newJson?.ad_groups_data[0]?.product_targeting;
    } else {
      // delete newJson?.ad_groups_data[0]?.default_bid;
      newJson.ad_groups_data[0].default_bid = 0;
    }
    console.log(newJson, "-newJson");
    set_loading(true);
    CreateCampaign(newJson);
  };

  const MakeManualCampaignJson = () => {
    const productList = selected_product_list?.map((d) => {
      return { sku: d?.seller_sku, asin: d?.asin1, state: d?.state };
    });
    const keywordTargetingData = selected_keyword_list.flatMap((item) => {
      const matchTypes = ["exact", "phrase", "broad"];
      return matchTypes
        .filter((type) => item[type])
        .map((type) => ({
          keyword: item[type].keyword_name,
          bid: item[type].sugg_bid,
          match_type: item[type].name,
          state: item[type].state,
        }));
    });

    const categoryTargetingData = selected_categroy_list?.map((d) => {
      return {
        expression_type: "ASIN_CATEGORY_SAME_AS",
        expression_value: d?.category_id,
        bid: d?.new_suggested_bid,
        state: "ENABLED",
      };
    });
    const productTargetingData = selected_individual_product_list.flatMap(
      (item) => {
        const result = [];
        if (item?.exact) {
          result.push({
            expression_type: item?.exact?.expression_type,
            expression_value: item?.exact?.expression_value,
            bid: item?.exact?.suggested_bid,
            state: item?.exact?.state,
          });
        }
        if (item?.expanded) {
          result.push({
            expression_type: item?.expanded?.expression_type,
            expression_value: item?.expanded?.expression_value,
            bid: item?.expanded?.suggested_bid,
            state: item?.expanded?.state,
          });
        }
        return result;
      }
    );

    console.log(
      all_common_hide_show?.manual_product_targeting,
      " all_common_hide_show?.manual_product_targeting"
    );
    const newJson = {
      marketplace_id: selected_marketplace,
      campaign_data: campaign_data_list,
      ad_groups_data: [
        {
          name: selected_adgroup_list?.[0],
          default_bid: "0",
          state: "ENABLED",
          product_ads: productList,
          product_targeting:
            all_common_hide_show?.manual_product_targeting ===
            "individual_products"
              ? productTargetingData
              : categoryTargetingData,
          keyword_targeting: keywordTargetingData,
          negative_keyword_targeting: selected_negative_keyword,
          negative_product_targeting: selected_negative_brand_product?.map(
            (d) => {
              return {
                expression_type: d?.expression_type,
                state: d?.state,
                expression_value: d?.expression_value,
              };
            }
          ),
        },
      ],
    };
    if (manual_sub_targeting_type === "keyword_targeting") {
      delete newJson.ad_groups_data[0].product_targeting;
    } else {
      delete newJson.ad_groups_data[0].keyword_targeting;
    }
    console.log(newJson, "-newJson");
    // setTimeout(() => {
    //   console.log(newJson, "-newJson");
    // }, 2000);

    set_loading(true);
    CreateCampaign(newJson);
  };

  const gotToNextStep = () => {
    const newStep =
      selectedStep === "step1"
        ? "step2"
        : selectedStep === "step2"
        ? "step3"
        : selectedStep === "step3"
        ? "step4"
        : "step5";
    const isCheck = isCheckAllStep();
    if (!isCheck) return;
    setSelectedStep(newStep);
  };

  const isCheckAllStep = () => {
    if (selectedStep === "step1") {
      if (
        !campaign_data_list?.name ||
        !campaign_data_list?.state ||
        !campaign_data_list?.start_date ||
        !campaign_data_list?.daily_budget ||
        // !campaign_data_list?.daily_limit ||
        campaign_data_list?.top_of_search_placement == null ||
        campaign_data_list?.rest_of_search_placement == null ||
        campaign_data_list?.product_pages_placement == null
      ) {
        DisplayNotification(
          "error",
          "Error",
          "Please fill all the required fields"
        );
        return false;
      }
      return true;
    }
    if (selectedStep === "step2") {
      const isCheck = selected_product_list?.length > 0;
      if (!isCheck) {
        DisplayNotification(
          "error",
          "Error",
          "Please add at least one product"
        );
        return false;
      }
      return true;
    }
    if (selectedStep === "step3") {
      const isCheck = selected_adgroup_list?.length > 0;
      if (!isCheck) {
        DisplayNotification(
          "error",
          "Error",
          "Please add at least one ad group"
        );
        return false;
      }
      return true;
    }
    if (selectedStep === "step4") {
      return true;
    }
    if (selectedStep === "step5") {
      const isCheck = selected_keyword_list?.length > 0;
      if (!isCheck) {
        DisplayNotification(
          "error",
          "Error",
          "Please add at least one keyword"
        );
        return false;
      }
      return true;
    }
  };
  return (
    <>
      <div
        className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row align-items-start"
        id="kt_stepper_example_vertical"
      >
        <SPSteps
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          campaign_data_list={campaign_data_list}
        />
        <div className="flex-row-fluid">
          <div style={{ minHeight: 500 }}>
            <div className="mb-5 ">
              {/* ---------------------------------------------------------- Step 1 ----------- */}
              {selectedStep === "step1" && (
                <SPStep1
                  campaign_data_list={campaign_data_list}
                  updateCampaignDataList={UpdateCampaignDataList}
                  // run_indefinitely_end_time={run_indefinitely_end_time}
                  // set_run_indefinitely_end_time={set_run_indefinitely_end_time}
                  auto_targeting_radio={auto_targeting_radio}
                  set_auto_targeting_radio={set_auto_targeting_radio}
                  campaign_bidding_strategy_label={
                    campaign_bidding_strategy_label
                  }
                  set_campaign_bidding_strategy_label={
                    set_campaign_bidding_strategy_label
                  }
                  portfolioList={portfolioList}
                  manual_sub_targeting_type={manual_sub_targeting_type}
                  set_manual_sub_targeting_type={set_manual_sub_targeting_type}
                  selected_marketplace={selected_marketplace}
                  selected_currency_sign={selected_currency_sign}
                />
              )}

              {/* ---------------------------------------------------------- Step 2 ----------- */}
              {selectedStep === "step2" && (
                <SPStep2
                  selected_adgroup_list={selected_adgroup_list}
                  product_list={product_list}
                  set_product_list={set_product_list}
                  selected_product_list={selected_product_list}
                  set_selected_product_list={set_selected_product_list}
                  all_common_hide_show={all_common_hide_show}
                  set_all_common_hide_show={set_all_common_hide_show}
                  all_filters={all_filters}
                  set_all_filters={set_all_filters}
                  GetProductList={(filter) => {
                    GetProductList(filter);
                  }}
                  product_loading={product_loading}
                  set_product_loading={set_product_loading}
                  totalPage={totalPage}
                  onPageNo={onPageNo}
                  onPerPage={onPerPage}
                  selected_marketplace={selected_marketplace}
                />
              )}

              {/* ---------------------------------------------------------- Step 3 ----------- */}
              {selectedStep === "step3" && (
                <SPStep3
                  campaign_data_list={campaign_data_list}
                  selected_adgroup_list={selected_adgroup_list}
                  set_selected_adgroup_list={set_selected_adgroup_list}
                  auto_targeting_radio={auto_targeting_radio}
                  set_auto_targeting_radio={set_auto_targeting_radio}
                  ad_group_product_targeting={ad_group_product_targeting}
                  set_ad_group_product_targeting={
                    set_ad_group_product_targeting
                  }
                  ad_group_default_bid={ad_group_default_bid}
                  set_ad_group_default_bid={set_ad_group_default_bid}
                  selected_product_list={selected_product_list}
                  set_campaign_bidding_strategy_label={
                    set_campaign_bidding_strategy_label
                  }
                  updateCampaignDataList={UpdateCampaignDataList}
                  set_common_default_bid={set_common_default_bid}
                  selected_marketplace={selected_marketplace}
                  selected_currency_sign={selected_currency_sign}
                />
              )}

              {/* ---------------------------------------------------------- Step 4 ----------- */}
              {selectedStep === "step4" && (
                <SPStep4
                  all_common_hide_show={all_common_hide_show}
                  set_all_common_hide_show={set_all_common_hide_show}
                  selected_negative_keyword={selected_negative_keyword}
                  set_selected_negative_keyword={set_selected_negative_keyword}
                  campaign_data_list={campaign_data_list}
                  manual_sub_targeting_type={manual_sub_targeting_type}
                  selected_negative_brand_product={
                    selected_negative_brand_product
                  }
                  set_selected_negative_brand_product={
                    set_selected_negative_brand_product
                  }
                  selected_marketplace={selected_marketplace}
                />
              )}

              {/* ---------------------------------------------------------- Step 5 ----------- */}
              {campaign_data_list?.targeting_type === "MANUAL" &&
              selectedStep === "step5" ? (
                <SPStep5
                  campaign_data={campaign_data_list}
                  selected_product_list={selected_product_list}
                  manual_sub_targeting_type={manual_sub_targeting_type}
                  set_manual_sub_targeting_type={set_manual_sub_targeting_type}
                  selected_keyword_list={selected_keyword_list}
                  set_selected_keyword_list={set_selected_keyword_list}
                  selected_individual_product_list={
                    selected_individual_product_list
                  }
                  set_selected_individual_product_list={
                    set_selected_individual_product_list
                  }
                  selected_categroy_list={selected_categroy_list}
                  set_selected_categroy_list={set_selected_categroy_list}
                  all_common_hide_show={all_common_hide_show}
                  set_all_common_hide_show={set_all_common_hide_show}
                  common_default_bid={common_default_bid}
                  set_common_default_bid={set_common_default_bid}
                  selected_currency_sign={selected_currency_sign}
                  selected_marketplace={selected_marketplace}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="d-flex flex-stack">
            <div className="me-2">
              {selectedStep !== "step1" && (
                <button
                  type="button"
                  className="btn btn-dark fw-bold fs-7"
                  onClick={() => {
                    const newStep =
                      selectedStep === "step5"
                        ? "step4"
                        : selectedStep === "step4"
                        ? "step3"
                        : selectedStep === "step3"
                        ? "step2"
                        : "step1";
                    setSelectedStep(newStep);
                  }}
                >
                  Back
                </button>
              )}
            </div>

            <div>
              <button
                type="button"
                className="btn btn-primary fs-7 fw-bold"
                data-kt-stepper-action="submit"
              >
                <span className="indicator-label">Launch Campaign</span>
                <span className="indicator-progress">
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              </button>

              <button
                type="button"
                className="btn btn-primary fs-7 fw-bold"
                disabled={loading}
                onClick={() => {
                  campaign_data_list?.targeting_type === "AUTO" &&
                  selectedStep === "step4"
                    ? MakeAutoCampaignJson()
                    : selectedStep === "step5"
                    ? MakeManualCampaignJson()
                    : gotToNextStep();
                }}
              >
                {loading ? (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                ) : campaign_data_list?.targeting_type === "AUTO" &&
                  selectedStep === "step4" ? (
                  "Submit"
                ) : selectedStep === "step5" ? (
                  "Submit"
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SponsoredProducts;
