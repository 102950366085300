// import Icons from "../../../../components/icons";

import { GetLinks } from "../../../../core";

export default [
  {
    key: "/master-table",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-tablet-book   fs-1"></i>
      </span>
    ),
    label: "Master Table",
    children: [
      {
        key: "/master-table/product-details",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/master-table/product-details", "Product Details"),
      },
      {
        key: "/master-table/recommendation-alerts",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks(
          "/master-table/recommendation-alerts",
          "Recommendation & Alerts"
        ),
      },
    ],
  },
];
