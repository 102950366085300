// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/recommendation-alerts",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-notification-on fs-1"></i>
      </span>
    ),
    label: GetLinks("/recommendation-alerts", "Recommendation & Alerts"),
    admin: true,
  },
];
