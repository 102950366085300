import { Radio } from "antd";
import React, { useState } from "react";
import CampaignList from "./campaign-list/campaign-list";
import CampaignCreation from "./campaign-creation/campaign-creation";

export default function (props) {
  const [mode, setMode] = useState("campaign-list");
  // const handleModeChange = (e) => {
  //   setMode(e.target.value);
  // };

  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="row mb-8 px-0 mx-0">
        <div
          className="col-12 px-0 mx-0"
          style={{ borderBottom: "1px solid #e6332a" }}
        >
          <button
            className="btn btn-sm mx-2 px-7 fs-6"
            style={{
              background: "#e6332a0f",
              borderRadius: " 20px 20px 0px 0px",
              color: "rgb(230, 51, 42)",
              borderColor:
                mode === "campaign-list"
                  ? "#e6332ac2"
                  : "rgba(230, 51, 42, 0.09)",
              borderStyle: "solid",
              borderWidth: "1px 1px 0px 1px",
            }}
            onClick={() => {
              setMode("campaign-list");
            }}
          >
            Campaign List
          </button>
          <button
            className="btn btn-sm mx-2 px-7 fs-6"
            style={{
              background: "#e6332a0f",
              borderRadius: "20px 20px 0px 0px",
              color: "rgb(230, 51, 42)",
              borderColor:
                mode === "campaign-creation"
                  ? "#e6332ac2"
                  : "rgba(230, 51, 42, 0.09)",
              borderStyle: "solid",
              borderWidth: "1px 1px 0px 1px",
            }}
            // style={{
            //   background:
            //     mode === "campaign-creation"
            //       ? "#e6332a"
            //       : "rgb(230 51 42 / 9%)",
            //   color: mode === "campaign-creation" ? "#fff" : "#e6332a",
            //   border: "1px dashed #e6332a59",
            // }}
            onClick={() => {
              setMode("campaign-creation");
            }}
          >
            Campaign Creation
          </button>
          {/* <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{
              marginBottom: 8,
            }}
          >
            <Radio.Button value="campaign-list">Campaign List</Radio.Button>
            <Radio.Button value="campaign-creation">
              Campaign Creation
            </Radio.Button>
          </Radio.Group> */}
        </div>
      </div>
      <div className="row">
        {mode === "campaign-list" && (
          <CampaignList
            selected_marketplace={selected_marketplace}
            set_selected_marketplace={set_selected_marketplace}
            marketplace_list={marketplace_list}
            set_marketplace_list={set_marketplace_list}
            currency_list={currency_list}
            set_currency_list={set_currency_list}
            selected_currency_sign={selected_currency_sign}
            set_selected_currency_sign={set_selected_currency_sign}
          />
        )}
      </div>
      <div className="row">
        {mode === "campaign-creation" && (
          <CampaignCreation
            selected_marketplace={selected_marketplace}
            selected_currency_sign={selected_currency_sign}
            setMode={setMode}
          />
        )}
      </div>
    </div>
  );
}
