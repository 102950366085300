import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis/make-api-call";
import { GlobalContext } from "../../../../commonContext";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  ENDPOINT,
  PhotoUrl,
  getTableLoading,
} from "../../../../config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Input, Popconfirm, message } from "antd";
import AddUser from "./lib/add-user";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/esf-table/lib/pagination";

const ManageUser = () => {
  const contextValue = useContext(GlobalContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [user_list, set_user_list] = useState([]);
  const [user_data, set_user_data] = useState({});
  const [isSwitching, setIsSwitching] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search_key: "",
  });

  useEffect(() => {
    GetUserList(all_filters);
  }, []);

  const GetUserList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `user${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount);
      set_user_list(response?.data?.records);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const SwitchUser = async (id) => {
    const response = await MakeApiCall(
      `user/switch-user/${id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      setIsSwitching(false);
      SetUserInformation(contextValue, response?.data, "switch_user");
      if (response?.data?.user_data?.user_type == 2) {
        GetSubUserList();
      } else {
        navigate("/dashboard");
      }
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetSubUserList = async () => {
    //  const data = {
    //    user_type: 2,
    //    parent: 2,
    //  };
    localStorage.setItem("token_type", "main_token");
    const response = await MakeApiCall(
      `user/get-all-sub-user`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      if (response?.data?.length > 0) {
        localStorage.setItem("token_type", "");
        localStorage.setItem("sub_user_list", JSON.stringify(response?.data));
        contextValue?.updateCommonUserListVal({
          user_list: JSON.stringify(response?.data),
        });
        navigate("/dashboard");
      } else {
        localStorage.removeItem("sub_user_list");
        navigate("/dashboard");
      }
    } else {
      localStorage.setItem("token_type", "");
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const DeleteUser = async (id) => {
    const response = await MakeApiCall(`user/${id}`, `delete`, null, true);
    if (response?.status) {
      setLoading(true);
      GetUserList(all_filters);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const SetUserInformation = (contextValue, response) => {
    let adminToken = localStorage.getItem("token");
    let adminData = localStorage.getItem("user_data");
    const data = {
      token: adminToken,
      user_data: adminData,
    };
    contextValue?.updateCommonGlobalVal({
      admin_data: JSON.stringify(data),
      isAdmin: 1,
      user_data: JSON.stringify(response?.user_data),
      user_type: response?.user_data?.user_type,
      primary_marketplace: response?.user_data?.default_marketplace || null,
      selected_sub_user: response?.user_data?.sub_user?.name || null,
    });
    localStorage.setItem("admin_data", JSON.stringify(data));
    localStorage.setItem("isAdmin", 1);
    localStorage.setItem(
      "primary_marketplace",
      response?.user_data?.default_marketplace || null
    );
    localStorage.setItem("user_data", JSON.stringify(response?.user_data));
    localStorage.setItem("token", response?.auth_token);
    localStorage.setItem("user_type", response?.user_data?.user_type);
    localStorage.setItem(
      "sub_user_token",
      response?.user_data?.sub_user?.auth_token
    );
    localStorage.setItem(
      "selected_sub_user",
      response?.user_data?.sub_user?.name || null
    );
  };

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    GetUserList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    GetUserList(apiObj);
  };

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        <div className="row g-5 mb-7">
          <div className="col-md-6">
            <div className="position-relative mr-4">
              <Input
                style={{ width: "250px" }}
                prefix={
                  <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                }
                placeholder="Search by name"
                size="large"
                value={all_filters?.search}
                onChange={(e) => {
                  set_all_filters({
                    ...all_filters,
                    search: e.target.value,
                  });
                }}
                onPressEnter={(e) => {
                  const apiObj = {
                    ...all_filters,
                    search: e.target.value,
                  };
                  setLoading(true);
                  GetUserList(apiObj);
                }}
              />
            </div>
          </div>
          <div className="col-md-6 text-md-end">
            <div className="d-flex justify-content-end">
              <span
                className="btn  fs-7 btn-primary fw-bold"
                onClick={() => {
                  setIsOpenUserModal(true);
                }}
              >
                Add New User
              </span>
            </div>
          </div>
        </div>
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
              <div className="card-header ">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Members Statistics
                  </span>
                  <span className="text-muted mt-1 fw-semibold fs-7">
                    Over 500 members
                  </span>
                </h3>
                <div className="card-toolbar"></div>
              </div>

              <div className="card-body py-2">
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-200px">Name</th>
                          <th className="min-w-125px">User Type</th>
                          <th className="min-w-125px">Mobile No</th>
                          <th className="min-w-175px text-center">
                            Switch User
                          </th>
                          <th className="min-w-175px text-center">
                            Pending Reports
                          </th>
                          {/* <th className="min-w-80px">Role</th> */}
                          <th className="min-w-175px">Created at</th>
                          <th className="min-w-175px">Updated at</th>
                          <th className="min-w-100px text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {loading ? (
                          getTableLoading(9)
                        ) : user_list?.length > 0 ? (
                          user_list?.map((d, i) => {
                            return (
                              <tr className="bg-light">
                                <td className="bdr-left-user">
                                  {(all_filters?.page - 1) *
                                    all_filters?.["per-page"] +
                                    1 +
                                    i}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                      {d?.photo ? (
                                        <img
                                          src={`${PhotoUrl}${d?.photo}`}
                                          alt
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            height: "50px",
                                            width: "50px",
                                            background: "#e6332a",
                                            borderRadius: "30px",
                                            color: "rgb(255, 255, 255)",
                                            fontWeight: "500",
                                            fontSize: "22px",
                                            boxShadow:
                                              "#a39797 0px -50px 36px -28px inset",
                                          }}
                                          className="d-flex justify-content-center align-items-center"
                                        >
                                          <span
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {d?.name?.charAt(0)}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="d-flex flex-column">
                                      <span className="text-primary mb-1 fw-bolder gotham_black">
                                        {d?.name || "-"}
                                      </span>
                                      <span>{d?.email || "-"}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {d?.user_type == 2
                                    ? "User"
                                    : d?.user_type == "3"
                                    ? "Seller"
                                    : "" || "-"}
                                </td>
                                <td>{d?.contact_no || "-"}</td>
                                <td>
                                  <span
                                    onClick={() => {
                                      setIsSwitching(true);
                                      message.loading("Switching...", 0);
                                      SwitchUser(d?.id);
                                    }}
                                    className="d-flex align-items-center py-2 justify-content-center text-dark cursor-pointer"
                                    disabled={isSwitching}
                                  >
                                    <i className="ki-duotone ki-fasten fs-1 text-primary">
                                      <span className="path1" />
                                      <span className="path2" />
                                      <span className="path3" />
                                    </i>
                                    <span className="fw-bold text-dark ms-2">
                                      Switch Account
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center py-2 justify-content-center">
                                    <span className="fw-bold">
                                      {d?.pending_reports || "-"}
                                    </span>
                                  </div>
                                </td>
                                {/* <td>User</td> */}
                                <td>
                                  {moment(
                                    new Date(d?.created_at * 1000)
                                  ).format("MMM DD, YYYY hh:mm A")}
                                </td>
                                <td>
                                  {moment(
                                    new Date(d?.updated_at * 1000)
                                  ).format("MMM DD, YYYY hh:mm A")}
                                </td>
                                <td>
                                  <div className="d-flex flex-shrink-0 justify-content-center">
                                    <span
                                      className="btn btn-icon bg-light btn-active-color-success btn-sm me-2"
                                      onClick={() => {
                                        set_user_data(d);
                                        setIsOpenUserModal(true);
                                      }}
                                    >
                                      <i className="ki-outline ki-pencil fs-2 text-success" />
                                    </span>
                                    <Popconfirm
                                      title="Delete User"
                                      description="Are you sure to delete this user?"
                                      onConfirm={() => {
                                        DeleteUser(d?.id);
                                      }}
                                      onCancel={() => {}}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <span className="btn btn-icon bg-light btn-active-color-primary btn-sm">
                                        <i className="ki-outline ki-trash fs-2 text-primary" />
                                      </span>
                                    </Popconfirm>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <NoData type="new" />
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    loading={loading || user_list?.length === 0}
                    pageSize={all_filters?.["per-page"]}
                    page={all_filters?.page}
                    totalPage={totalPage}
                    onPerPage={onPerPage}
                    onPageNo={onPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenUserModal && (
        <AddUser
          show={isOpenUserModal}
          onHide={() => {
            setIsOpenUserModal(false);
            set_user_data({});
            GetUserList(all_filters);
          }}
          data={user_data}
        />
      )}
    </>
  );
};

export default ManageUser;
