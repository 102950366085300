import styled from "styled-components";

export const RecommendationStyle = styled.div`
  // .recom-card-wrapper {
  //   display: flex;
  //   justify-content: space-around;
  //   padding: 0px;
  //   flex-wrap: wrap;
  // }
  .card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 14px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
    // width: 200px;
    // text-align: center;
    // margin: 10px;
  }
  .card .icon {
    font-size: 24px;
    transition: transform 0.3s ease;
    // margin-bottom: 10px;
  }
  .card .value {
    font-size: 25px;
    font-weight: bold;
  }
  .card .change {
    // color: #e3554e;
    font-size: 14px;
    font-weight: 500;
  }

  .out_of_stock:hover {
    background-color: #f5f5ff;
    transform: scale(1.01);
    border: 1px solid #8d2ce1;
  }
  .out_of_stock:hover .icon {
    transform: scale(1.1);
  }

  .non_advertised:hover {
    background-color: #ffeaf0;
    transform: scale(1.01);
    border: 1px solid #f2235f;
  }
  .non_advertised:hover .icon {
    transform: scale(1.1);
  }

  .buybox_affected:hover {
    background-color: #f6fffd;
    transform: scale(1.01);
    border: 1px solid #11b391;
  }
  .buybox_affected:hover .icon {
    transform: scale(1.1);
  }

  .different_skus:hover {
    background-color: #fef9f4;
    transform: scale(1.01);
    border: 1px solid #f7981e;
  }
  .different_skus:hover .icon {
    transform: scale(1.1);
  }

  .ineligible_ads:hover {
    background-color: #f2f9ed;
    transform: scale(1.01);
    border: 1px solid #84c64a;
  }
  .ineligible_ads:hover .icon {
    transform: scale(1.1);
  }

  .campaigns_rejected:hover {
    background-color: #fef9f4;
    transform: scale(1.01);
    border: 1px solid #f7981e;
  }
  .campaigns_rejected:hover .icon {
    transform: scale(1.1);
  }

  .payment_advertised_account:hover {
    background-color: #f9fafc;
    transform: scale(1.01);
    border: 1px solid #527ca9;
  }
  .payment_advertised_account:hover .icon {
    transform: scale(1.1);
  }

  .acos_by_higher_margin:hover {
    background-color: #ffeaf0;
    transform: scale(1.01);
    border: 1px solid #f2235f;
  }
  .acos_by_higher_margin:hover .icon {
    transform: scale(1.1);
  }

  .advertised_performance_trend:hover {
    background-color: #f5f3f7;
    transform: scale(1.01);
    border: 1px solid #956aa9;
  }
  .advertised_performance_trend:hover .icon {
    transform: scale(1.1);
  }

  .negative_product_review:hover {
    background-color: #f6f4fd;
    transform: scale(1.01);
    border: 1px solid #4b01e0;
  }
  .negative_product_review:hover .icon {
    transform: scale(1.1);
  }

  .negative_seller_feedback:hover {
    background-color: #fff5f0;
    transform: scale(1.01);
    border: 1px solid #fc5a15;
  }
  .negative_seller_feedback:hover .icon {
    transform: scale(1.1);
  }

  .product_issue_duration:hover {
    background-color: #f7fbf4;
    transform: scale(1.01);
    border: 1px solid #84c64a;
  }
  .product_issue_duration:hover .icon {
    transform: scale(1.1);
  }

  .sales_price_change:hover {
    background-color: #ffeaf0;
    transform: scale(1.01);
    border: 1px solid #f2235f;
  }
  .sales_price_change:hover .icon {
    transform: scale(1.1);
  }
`;
