// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/advertisement-report",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-chart-pie-4 fs-1"></i>
      </span>
    ),
    label: GetLinks("/advertisement-report", "Advertisement Report"),
  },
];
