

import UserAccounts from "../modules/pages/user-accounts/user-account";
export default [
  {
    path: "/user-accounts",
    component: <UserAccounts />,
    exact: true,
  },
];
