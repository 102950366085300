import { Popover, Select, Switch } from "antd";
import React from "react";

const SBProducts = (props) => {
  const {
    product_list,
    selected_product_list,
    set_selected_product_list,
    all_common_hide_show,
    set_all_common_hide_show,
  } = props;
  return (
    <>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-6">
            <div className="card min-h-450px">
              <div className="card-header card-header-stretch">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Products</h3>
                </div>
                <div className="card-toolbar">
                  <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          add_items: "search",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${all_common_hide_show?.add_items === "search"
                            ? "active"
                            : ""
                          }`}
                      >
                        Search
                      </span>
                    </li>
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          add_items: "enter_list",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${all_common_hide_show?.add_items === "enter_list"
                            ? "active"
                            : ""
                          }`}
                      >
                        Enter List
                      </span>
                    </li>
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          add_items: "upload",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${all_common_hide_show?.add_items === "upload"
                            ? "active"
                            : ""
                          }`}
                      >
                        Upload
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade  ${all_common_hide_show?.add_items === "search"
                        ? "show active"
                        : ""
                      }`}
                  >
                    <div className="mt-0">
                      <div className="position-relative mr-4">
                        <i className="ki-outline ki-magnifier text-gray-800 fs-2 position-absolute top-50 translate-middle-y ms-4" />
                        <input
                          type="text"
                          data-kt-table-widget-4="search"
                          className="form-control text-dark fs-7 ps-12 all-search"
                          name="all_search"
                          placeholder="Search by product name or ASIN"
                        />
                      </div>
                      <p className="mb-0 mt-3 fs-7 text-primary">
                        Search for products you want to exclude.
                      </p>
                    </div>
                    <div
                      className="mt-5"
                      style={{ height: "318px", overflow: "scroll" }}
                    >
                      {product_list?.map((d, i) => {
                        const isProductAdded = selected_product_list?.filter(
                          (f) => f?.asin1 === d?.asin1
                        );
                        return (
                          <div className="rounded bg-light p-2 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                <img src={d?.image_url} className alt />
                              </div>
                              <div className="d-flex flex-column me-2 fs-7">
                                <div className="d-flex">
                                  <Popover
                                    content={
                                      <div
                                        style={{
                                          height: "max-content",
                                          maxWidth: "400px",
                                        }}
                                      >
                                        {d?.item_name}
                                      </div>
                                    }
                                    placement="bottom"
                                  >
                                    <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                      {d?.item_name}
                                    </span>
                                  </Popover>
                                </div>
                                <span className="text-gray-700 fs-8">
                                  ASIN:{" "}
                                  <b className="text-primary">{d?.asin1}</b> /
                                  SKU:{" "}
                                  <b className="text-primary">
                                    {" "}
                                    {d?.seller_sku}
                                  </b>
                                </span>
                              </div>
                              <div>
                                {isProductAdded?.length == 0 && (
                                  <span
                                    className="btn btn-light bg-white btn-sm btn-icon"
                                    onClick={() => {
                                      set_selected_product_list([
                                        ...selected_product_list,
                                        { ...d, state: "ENABLED" },
                                      ]);
                                    }}
                                  >
                                    <i className="ki-outline ki-plus fs-3 text-danger" />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${all_common_hide_show?.add_items === "enter_list"
                        ? "show active"
                        : ""
                      }`}
                  >
                    <div className>
                      <textarea
                        className="form-control  fs-7"
                        name
                        id
                        cols={30}
                        rows={10}
                        placeholder="Enter ASINs seprated by comma, space or new line."
                        defaultValue={""}
                      />
                      <div className="mt-3 text-end">
                        <button className="btn btn-dark fs-7">Add</button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${all_common_hide_show?.add_items === "upload"
                        ? "show active"
                        : ""
                      }`}
                  >
                    <p className="mb-3 mt-2 text-muted">
                      Use the template to make sure your information uploads
                      accurately.
                    </p>
                    <div className="mb-5">
                      <span className="fw-bold text-primary">
                        <i className="bi bi-download text-primary" />
                        Download the csv template
                      </span>
                    </div>

                    <div className="fv-row">
                      <div className="dropzone" id="kt_dropzonejs_example_1">
                        <div className="dz-message needsclick">
                          <i className="ki-duotone ki-file-up fs-3x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>

                          <div className="ms-4">
                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                              Drop files here or click to upload.
                            </h3>
                            <span className="fs-7 fw-semibold text-gray-600">
                              Accepted formats: CSV, TSV, XLSX
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-6">
            <div className="card min-h-450px">
              <div className="card-header ">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">
                    {selected_product_list?.length} Products
                  </h3>
                </div>
                <div className="card-toolbar">
                  <span className="btn fs-7 btn-light-primary fw-bold btn-sm">
                    Remove All
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div
                  className="mt-0"
                  style={{ height: "403px", overflow: "scroll" }}
                >
                  {selected_product_list?.map((d) => {
                    return (
                      <div className="rounded bg-light p-3 mb-4">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-60px overflow-hidden me-3">
                            <img src={d?.image_url} className alt />
                          </div>
                          <div className="d-flex flex-column">
                            <div className="d-flex">
                              <Popover
                                content={
                                  <div
                                    style={{
                                      height: "max-content",
                                      maxWidth: "400px",
                                    }}
                                  >
                                    {d?.item_name}
                                  </div>
                                }
                                placement="bottom"
                              >
                                <span className="text-gray-800 mb-1 popoverActionIconTwoLine fw-bolder fs-7">
                                  {d?.item_name}
                                </span>
                              </Popover>
                              <div>
                                <span
                                  className="btn btn-light bg-white btn-sm btn-icon"
                                  onClick={() => {
                                    const isDeleted =
                                      selected_product_list?.filter(
                                        (f) => f?.asin1 !== d?.asin1
                                      );
                                    set_selected_product_list(isDeleted);
                                  }}
                                >
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </span>
                              </div>
                            </div>
                            <span
                              className="text-gray-700"
                              style={{ fontSize: "11px !important" }}
                            >
                              <Switch
                                size="small"
                                checked={d?.state === "ENABLED" ? true : false}
                                onChange={(e) => {
                                  set_selected_product_list((prev) => {
                                    return prev?.map((a) => {
                                      if (a?.asin1 === d?.asin1) {
                                        return {
                                          ...d,
                                          state: e ? "ENABLED" : "DISABLED",
                                        };
                                      } else {
                                        return a;
                                      }
                                    });
                                  });
                                }}
                              />{" "}
                              ASIN: <b className="text-primary"> {d?.asin1}</b>{" "}
                              / SKU:{" "}
                              <b className="text-primary">{d?.seller_sku}</b>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SBProducts;
