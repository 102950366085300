import { Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DisplayNotification, urlDecode } from "../../../config";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { TempMakeApiCall } from "../../../apis/temp-make-api-call";
import AdList from "./lib/adList";

function ClientInvitation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user_token, set_user_token] = useState("");
  const [list, setList] = useState([]);
  const [adListModal, setAdListModal] = useState(false);
  const [adList, setAdList] = useState();
  const [account_info, set_account_info] = useState({
    marketplace_id: null,
    seller_name: "",
  });

  useEffect(() => {
    if (location?.search) {
      const { token, code, marketplace_id, seller_name } = urlDecode(location);
      if (token) {
        GetUserToken(token);
        return;
      } else {
        localStorage.setItem("adCode", code);
        navigate(location?.pathname);
        message.destroy();
        message.loading("Loading...", 0);
        GenerateAdRefreshToken({
          ad_code: localStorage.getItem("adCode"),
          marketplace_id,
          seller_name,
        });
      }
    }
  }, [location]);

  const GetUserToken = async (UrlToken) => {
    const response = await TempMakeApiCall(
      `user-token`,
      `post`,
      { token: UrlToken },
      false
    );
    if (response?.status) {
      set_user_token(response?.data?.auth_token);
      localStorage.setItem("temp_user_token", response?.data?.auth_token);
      setTimeout(() => {
        GetMarketplaceIds();
      }, 2000);
    } else {
      setLoading(false);
      // DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetMarketplaceIds = async () => {
    const response = await TempMakeApiCall(
      `marketplaces/get-marketplace-by-region`,
      "get",
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      setList(response?.data);
      setLoading(false);
      // window.close();
      // DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GenerateAdRefreshToken = async (data) => {
    const response = await TempMakeApiCall(
      `user-credential/generate-refresh-ad-token`,
      "post",
      data,
      true
    );
    if (response?.status) {
      message.destroy();
      localStorage.removeItem("adCode");
      setAdList(JSON.parse(response?.data));
      setAdListModal(true);
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  // if (localStorage.getItem("token")) {
  //   return (
  //     <Navigate
  //       to={
  //         localStorage.getItem("user_type") == 1 ? `/manage-user` : "/dashboard"
  //       }
  //     />
  //   );
  // }
  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
        style={{
          height: "100%",
          backgroundImage: `url(/assets/media/bg6.jpg)`,
        }}
      >
        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-40 mx-auto position-relative">
          <div className="b1" />
          <div className="b2" />

          <div
            className="d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 "
            style={{
              backgroundColor: "#0000002e",
              backdropFilter: "blur(18px)",
              border: "2px solid #ffffff26",
            }}
          >
            <div className=" text-center">
              <img src="/assets/media/logo.png" className="h-40px" alt />
            </div>
            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-10">
              <div className="form w-100">
                <div className="text-center mb-18">
                  <h1 className="tracking-in-expand-fwd  text-gray-100 fw-bold fs-2x mb-3">
                    Welcome in{" "}
                    <span className="text-primary">eStore Factory</span>
                  </h1>

                  <div className="text-gray-200 fw-semibold fs-6">
                    Please enter your account details.
                  </div>
                </div>

                <div className="fv-row mb-8">
                  <Select
                    className="w-100 h-60px"
                    size="large"
                    placeholder="Select Marketplace"
                    allowClear
                    options={list?.map((ml, i) => {
                      return {
                        label: <span>{ml?.name}</span>,
                        title: `${ml?.name}`,
                        options: Object.entries(ml?.marketplace_list)?.map(
                          (d) => {
                            return {
                              label: (
                                <>
                                  <img
                                    src={`/assets/media/domainImage/${d?.[0]}.png`}
                                    style={{ height: "20px" }}
                                    loading="lazy"
                                    className="me-3"
                                    onError={(e) => {
                                      e.target.src =
                                        "/assets/media/domainImage/red-flag.png";
                                    }}
                                  />
                                  {d?.[1]}
                                </>
                              ),
                              value: d?.[0],
                            };
                          }
                        ),
                      };
                    })}
                    value={account_info?.marketplace_id}
                    onChange={(e) => {
                      set_account_info({ ...account_info, marketplace_id: e });
                    }}
                    loading={loading}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>

                <div className="fv-row mb-3 position-relative">
                  <Input
                    className="form-control  h-60px "
                    placeholder="Enter Seller Name "
                    value={account_info?.seller_name}
                    onChange={(e) => {
                      set_account_info({
                        ...account_info,
                        seller_name: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="d-grid mb-10 mt-5">
                  {!account_info?.marketplace_id ||
                  !account_info?.seller_name ? (
                    <button
                      className="btn btn-warning fs-7 fw-bolder text-dark"
                      disabled={
                        !account_info?.marketplace_id ||
                        !account_info?.seller_name
                      }
                    >
                      <i className="fab fa-amazon text-dark fs-2" /> Login with
                      Amazon
                    </button>
                  ) : (
                    <a
                      onClick={() => {
                        window.onAmazonLoginReady = function () {
                          window.amazon.Login.setClientId(
                            "amzn1.application-oa2-client.cd1d81266e80444e97c6ae8795345d93"
                          );
                        };
                        let a = document.createElement("script");
                        a.type = "text/javascript";
                        a.async = true;
                        a.id = "amazon-login-sdk";
                        a.src =
                          "https://assets.loginwithamazon.com/sdk/na/login1.js";
                        const getRoot = document.getElementById("amazon-root");
                        getRoot.appendChild(a);

                        setTimeout(() => {
                          let options = {};
                          options.scope = "advertising::campaign_management";
                          options.response_type = "code";
                          window.amazon.Login.authorize(
                            options,
                            `${"https://members.bidbison.com/callback-ads"}?marketplace_id=${
                              account_info?.marketplace_id
                            }&seller_name=${account_info?.seller_name || ""}`
                          );
                        }, 2000);
                      }}
                      className="btn btn-warning fs-7 fw-bolder text-dark"
                    >
                      <i className="fab fa-amazon text-dark fs-2" /> Login with
                      Amazon
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-stack px-lg-10 justify-content-center ">
              <div className="text-gray-200 text-center fw-semibold fs-6">
                Not a Member yet?
                <span className="text-light">Sign up</span>
              </div>
            </div>
          </div>
        </div>
        <div id="amazon-root" />
      </div>
      {adListModal && (
        <AdList
          show={adListModal}
          data={adList?.map((d, i) => {
            return { ...d, key: i };
          })}
          advertisingList={list}
          onHide={() => {
            setAdList([]);
            setAdListModal(false);
          }}
        />
      )}
    </>
  );
}

export default ClientInvitation;
