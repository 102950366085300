import styled from "styled-components";

export const HeaderWrapper = styled.div`
  // .stickeyheader {
  //   position: sticky !important;
  //   border: 1px solid red !important;
  //   top: 0px !important;
  //   background: #f9f9f9 !important;
  //   z-index: 99;
  // }
`;
