import React, { useState } from "react";
import { Collapse, Popover, Select, Switch } from "antd";

const { Panel } = Collapse;

const STVManualTargeting = (props) => {
  const {
    targeting_list,
    selected_targeting_list,
    set_selected_targeting_list,
    all_common_hide_show,
    set_all_common_hide_show,
  } = props;

  const onChange = (key) => {
    console.log(key);
  };

  const genExtra = () => (
    <i className="ki-outline ki-check-circle me-3 fs-3 text-success" />
  );

  return (
    <>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-6">
            <div className="card min-h-450px">
              <div className="card-header card-header-stretch">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Targeting</h3>
                </div>

                <div className="card-toolbar">
                  <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          target_type: "search",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${all_common_hide_show?.target_type === "search"
                          ? "active"
                          : ""
                          }`}
                      >
                        Search
                      </span>
                    </li>
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          target_type: "browse",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${all_common_hide_show?.target_type === "browse"
                          ? "active"
                          : ""
                          }`}
                      >
                        Browse
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-body">
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade  ${all_common_hide_show?.target_type === "search"
                      ? "show active"
                      : ""
                      }`}
                  >
                    <div className="mb-5">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <span className="fw-bolder fs-6" htmlFor>Bid</span>
                        </div>
                        <div className="col-md-4 ">
                          <div className="input-group input-group-sm  w-100px">
                            <span className="input-group-text fw-bolder fs-6 bg-white" id="basic-addon1">$</span>
                            <input type="text" className="form-control fs-7  border-start-0" placeholder defaultValue="0.75" aria-label aria-describedby="basic-addon1" />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="d-flex justify-content-end">
                            <small className="fw-bold fs-7">Per 1K Impressions(CPM)</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-0">
                      <div className="position-relative mr-4">
                        <i className="ki-outline ki-magnifier text-gray-800 fs-2 position-absolute top-50 translate-middle-y ms-4" />
                        <input type="text" data-kt-table-widget-4="search" className="form-control text-dark fs-7 ps-12 all-search" name="all_search" placeholder="Search all targets" />
                      </div>
                    </div>
                    <div className="mt-5">
                      sd
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${all_common_hide_show?.target_type === "browse"
                      ? "show active"
                      : ""
                      }`}
                  >
                    <div className="mb-5">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <span className="fw-bolder fs-6" htmlFor>Bid</span>
                        </div>
                        <div className="col-md-4 ">
                          <div className="input-group input-group-sm  w-100px">
                            <span className="input-group-text fw-bolder fs-6 bg-white" id="basic-addon1">$</span>
                            <input type="text" className="form-control fs-7  border-start-0" placeholder defaultValue="0.75" aria-label aria-describedby="basic-addon1" />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="d-flex justify-content-end">
                            <small className="fw-bold fs-7">Per 1K Impressions(CPM)</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-5"
                      style={{ height: "318px", overflow: "scroll" }}
                    >
                      <Collapse
                        defaultActiveKey={[]}
                        onChange={onChange}
                        expandIconPosition='right'
                      >
                        {Object.entries(targeting_list).map(([category, items], index) => (
                          <Panel
                            header={category}
                            key={index.toString()}
                          >
                            {items.length === 0 ? (
                              <p>No items available</p>
                            ) : (
                              <div>
                                {items.map(item => {
                                  const isTargetAdded = selected_targeting_list?.filter(
                                    (f) => f?.id === item?.id
                                  );
                                  return (
                                    <div className="rounded bg-light p-2 mb-3">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex flex-column me-2 fs-7">
                                          <div className="d-flex">
                                            <Popover
                                              content={
                                                <div
                                                  style={{
                                                    height: "max-content",
                                                    maxWidth: "400px",
                                                  }}
                                                >
                                                  {item?.item_name}
                                                </div>
                                              }
                                              placement="bottom"
                                            >
                                              <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                                {item?.item_name}
                                              </span>
                                            </Popover>
                                          </div>
                                        </div>
                                        <div>
                                          {isTargetAdded?.length == 0 && (
                                            <span
                                              className="btn btn-light bg-white btn-sm btn-icon"
                                              onClick={() => {
                                                set_selected_targeting_list([
                                                  ...selected_targeting_list,
                                                  { ...item, state: "ENABLED" },
                                                ]);
                                              }}
                                            >
                                              <i className="ki-outline ki-plus fs-3 text-danger" />
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-6">
            <div className="card min-h-450px">
              <div className="card-header ">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">
                    {selected_targeting_list?.length} Targets added
                  </h3>
                </div>
                <div className="card-toolbar">
                  <span className="btn fs-7 btn-light-primary fw-bold btn-sm">
                    Remove All
                  </span>
                </div>
              </div>
              {selected_targeting_list.length !== 0 && (
                <div className="card-body">
                  <div
                    className="mt-0"
                    style={{ height: "403px", overflow: "scroll" }}
                  >
                    {selected_targeting_list?.map((d) => {
                      return (
                        <div className="rounded bg-light p-3 mb-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="">
                              <Popover
                                content={
                                  <div
                                    style={{
                                      height: "max-content",
                                      maxWidth: "400px",
                                    }}
                                  >
                                    {d?.item_name}
                                  </div>
                                }
                                placement="bottom"
                              >
                                <span className="text-gray-800 mb-1 popoverActionIconTwoLine fw-bolder fs-7">
                                  {d?.item_name}
                                </span>
                              </Popover>
                            </div>
                            <div>
                              <span
                                className="btn btn-light bg-white btn-sm btn-icon"
                                onClick={() => {
                                  const isDeleted =
                                    selected_targeting_list?.filter(
                                      (f) => f?.id !== d?.id
                                    );
                                  set_selected_targeting_list(isDeleted);
                                }}
                              >
                                <i className="ki-outline ki-trash fs-3 text-danger" />
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default STVManualTargeting;
