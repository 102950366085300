// import { lazy } from "react";
// import RuleManagement from "../modules/pages/rule-management";
// export default [
//   {
//     path: "/rule-management",
//     component: <RuleManagement />,
//     exact: true,
//   },
// ];



import Dayparting from "../modules/pages/day-parting/day-parting";
import RuleBook from "../modules/pages/rule-book/rule-book";

export default [
  {
    path: "/rule-management/rule-book",
    component: <RuleBook />,
    exact: true,
  },{
    path: "/rule-management/day-parting",
    component: <Dayparting />,
    exact: true,
  },
  // {
  //   path: "/keyword-management/keyword",
  //   component: <Keywords />,
  //   exact: true,
  // },
];