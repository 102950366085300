import React from "react";
import { Modal } from "react-bootstrap";
import { NumberWithCommas } from "../../../../../config";
import EsfTable from "../../../../../component/esf-table";
import { message, Popover } from "antd";
import Pagination from "../../../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../../../component/no-data-table";
import { GetColumns } from "./get-columns";
export default function ({
  show,
  onHide,
  data,
  all_filters,
  set_all_filters,
  GetProductList,
  totalPage,
  loading,
  setLoading,
  selectedType,
}) {
  console.log(data, "-data");
  const columns = [
    {
      title: "#",
      width: 20,
      fixed: "left",
      render: (text) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text?.key}
        </span>
      ),
    },
    {
      title: "Product",
      dataIndex: "item_name",
      key: "item_name",
      render: (text, record) => (
        <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
          <div className="symbol symbol-50px overflow-hidden me-3 shadow-sm">
            <img src={record?.image_url} />
          </div>
          <div className="d-flex flex-column">
            <Popover
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              content={
                <div
                  style={{
                    height: "max-content",
                    maxWidth: "400px",
                  }}
                >
                  {record?.item_name}
                </div>
              }
              placement="bottom"
            >
              <span
                className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                style={{ width: "300px" }}
              >
                {record?.item_name}
              </span>
            </Popover>

            <div>
              <span className="fs-7">
                <b>ASIN:</b>{" "}
                <span className="text-primary">{record?.asin1}</span>
              </span>{" "}
              |
              <span className="fs-7">
                <b>SKU:</b>{" "}
                <span className="text-primary">{record?.seller_sku}</span>
              </span>
            </div>
          </div>
        </div>
      ),
      width: 300,
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Ineligible Status",
      dataIndex: "ineligible_status",
      key: "ineligible_status",
    },
    {
      title: "Listing ID",
      dataIndex: "listing_id",
      key: "listing_id",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => `$${NumberWithCommas(text)}`, // Format price with dollar sign and two decimal places
    },
    {
      title: "Stock Quantity",
      dataIndex: "stock_quantity",
      key: "stock_quantity",
    },
    {
      title: "Stock Availability",
      dataIndex: "stock_availability",
      key: "stock_availability",
      render: (text) => text, // Render availability as text
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];
  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    message.destroy();
    message.loading("Loading...", 0);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    setLoading(true);
    GetProductList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    message.destroy();
    message.loading("Loading...", 0);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    setLoading(true);
    GetProductList(apiObj);
  };
  console.log(
    GetColumns(selectedType, all_filters),
    "-GetColumns(selectedType, all_filters)"
  );
  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Product List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: "300px" }}>
            <div className="table-responsive">
              {loading ? (
                <NoDataTable columns={columns} type="loading" />
              ) : (
                <EsfTable
                  columns={GetColumns(selectedType, all_filters)?.map((d) => ({
                    ...d,
                    width: d?.title?.length * 13,
                  }))}
                  sticky={{
                    offsetHeader: "10px",
                  }}
                  scroll={{
                    x: "max-content",
                    y: "calc(100vh - 100px)",
                  }}
                  fixed="top"
                  dataSource={data}
                  rowKey="key"
                  // loading={loading}
                  pagination={false}
                />
              )}
            </div>
            <Pagination
              loading={loading || data?.length === 0}
              pageSize={all_filters?.["per-page"]}
              page={all_filters?.page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
            disabled={loading}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
