import ProductDetails from "../modules/pages/master-table/product-details";
import RecommendationAndAlerts from "../modules/pages/master-table/recommendation-alerts";

export default [
  {
    path: "/product-details",
    component: <ProductDetails />,
    exact: true,
  },
  {
    path: "/recommendation-alerts",
    component: <RecommendationAndAlerts />,
    exact: true,
  },
];
