import { CloudUploadOutlined } from "@ant-design/icons";
import { Checkbox, Input, Select } from "antd";
import React, { useState } from "react";
import {
  DateFormat,
  DefaultPerPage,
  GetMarketplaceList,
} from "../../../../../config";
import RangePickerESF from "../../../../../component/range-picker";
import moment from "moment";
import dayjs from "dayjs";
const CampaignFilters = (props) => {
  const {
    set_selected_currency_sign,
    currency_list,
    all_filters,
    set_all_filters,
    marketplace_list,
    selected_marketplace,
    set_selected_marketplace,
    setLoading,
    GetApiCall,
    setIsOpenFilterDrawer,
    metrics_filter_obj,
    set_metrics_filter_obj,
    onApplyMetricsFilter,
  } = props;

  const [metrics_filter, set_metrics_filter] = useState({
    attributes_dd_data: {
      acos: "ACOS",
      roas: "ROAS",
      spends: "Spends",
      sales: "Total Sales",
      cpc: "CPC",
      cvr: "Conversion Rate",
      unit_ordered: "Orders",
      clicks: "Clicks",
      impressions: "Impressions",
      ctr: "CTR",
      ad_sales_percentage: "Ad Sales Percentage",
      total_sales: "Total Sales",
      total_orders: "Total Orders",
      total_acos: "Total ACOS",
    },
    operators_dd_data: {
      ">=": ">=",
      "<=": "<=",
      ">": ">",
      "<": "<",
      "==": "=",
    },
  });
  return (
    <div className="row">
      <div className="col-12">
        <Checkbox
          onChange={(e) => {
            console.log(e, "-e");
            if (e.target.checked) {
              const apiObj = {
                ...all_filters,
                page: 1,
                "per-page": DefaultPerPage,
                search_key: "",
                ad_type: null,
                campaign_name: "",
                state: null,
                start_date: dayjs().add(-30, "d"),
                end_date: dayjs(),
              };
              set_all_filters(apiObj);
              set_metrics_filter_obj({
                attribute: null,
                condition: null,
                value: "",
              });
              setLoading(true);
              GetApiCall(apiObj);
            }
          }}
        />
        <label className="text-primary fw-bold ms-2">Clear all filters</label>
      </div>
      <div className="separator separator-dashed my-4" />
      <div className="col-12 my-3">
        <label className="fw-bold">Date Range</label>
        <RangePickerESF
          className="ant_common_input w-100"
          id="ivcsr_date_picker"
          size="large"
          allowClear={false}
          format={DateFormat}
          value={[all_filters?.start_date, all_filters?.end_date]}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={(e) => {
            const filters_ = all_filters;
            filters_.start_date = e?.[0];
            filters_.end_date = e?.[1];
            set_all_filters({ ...filters_ });
          }}
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold">Campaign Type</label>
        <Select
          options={[
            {
              label: "Sponsored Product",
              value: "SPONSORED_PRODUCTS",
            },
            {
              label: "Sponsored Display",
              value: "SPONSORED_DISPLAY",
            },
            { label: "Sponsored Brand", value: "SPONSORED_BRAND" },
            { label: "Sponsored TV", value: "SPONSORED_TV" },
          ]}
          className="w-100"
          size="large"
          allowClear
          value={all_filters?.ad_type}
          onChange={(e) => {
            const apiObj = {
              ...all_filters,
              ad_type: e,
            };
            set_all_filters(apiObj);
          }}
          placeholder="Campaign Type"
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold">Campaign Status</label>
        <Select
          options={[
            { label: "PAUSED", value: "PAUSED" },
            { label: "ENABLED", value: "ENABLED" },
          ]}
          className="w-100"
          size="large"
          allowClear
          value={all_filters?.state}
          onChange={(e) => {
            const apiObj = {
              ...all_filters,
              state: e,
            };
            set_all_filters(apiObj);
          }}
          placeholder="Campaign Status"
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold"> Targeting Type</label>
        <Select
          options={[
            { label: "AUTO", value: "AUTO" },
            { label: "MANUAL", value: "MANUAL" },
          ]}
          className="w-100"
          size="large"
          allowClear
          value={all_filters?.targeting_settings}
          onChange={(e) => {
            const apiObj = {
              ...all_filters,
              targeting_settings: e,
            };
            set_all_filters(apiObj);
          }}
          placeholder="Targeting Type"
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold">Marketplace</label>
        <Select
          className="w-100"
          size="large"
          placeholder="Select Marketplace"
          options={GetMarketplaceList(marketplace_list)}
          value={selected_marketplace}
          onChange={(e) => {
            set_selected_marketplace(e);
            set_selected_currency_sign(currency_list[e]);
            const apiObj = {
              ...all_filters,
              page: 1,
              "per-page": DefaultPerPage,
            };
            set_all_filters(apiObj);
          }}
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold">Metrics</label>
        {metrics_filter_obj?.map((d, index) => {
          return (
            <div className="mt-2">
              <Select
                size="large"
                allowClear
                options={Object.entries(
                  metrics_filter?.attributes_dd_data
                )?.map((d) => {
                  return {
                    label: d[1],
                    value: d[0],
                  };
                })}
                value={d?.attribute}
                onChange={(e) => {
                  set_metrics_filter_obj((prev) => {
                    return prev?.map((d, mindex) => {
                      if (mindex === index) {
                        return {
                          ...d,
                          attribute: e,
                        };
                      }
                      return d;
                    });
                  });
                }}
                style={{ width: "150px" }}
              />
              <Select
                className="ms-1"
                size="large"
                allowClear
                options={Object.entries(metrics_filter?.operators_dd_data)?.map(
                  (d) => {
                    return {
                      label: d[1],
                      value: d[0],
                    };
                  }
                )}
                value={d?.operator}
                onChange={(e) => {
                  set_metrics_filter_obj((prev) => {
                    return prev?.map((d, mindex) => {
                      if (mindex === index) {
                        return {
                          ...d,
                          operator: e,
                        };
                      }
                      return d;
                    });
                  });
                }}
                style={{ width: "100px" }}
              />
              <Input
                size="large"
                style={{ width: "100px" }}
                className="ms-1"
                value={d?.value}
                onChange={(e) => {
                  set_metrics_filter_obj((prev) => {
                    return prev?.map((d, mindex) => {
                      if (mindex === index) {
                        return {
                          ...d,
                          value: e.target.value,
                        };
                      }
                      return d;
                    });
                  });
                }}
              />
              {metrics_filter_obj?.length > 1 && (
                <span
                  className="btn btn-icon btn-light-primary btn-outline w-40px h-40px position-relative border-0 cursor-pointer ms-1"
                  onClick={() => {
                    const deletedData = metrics_filter_obj?.filter(
                      (d, i) => i !== index
                    );
                    set_metrics_filter_obj(deletedData);
                  }}
                >
                  <i className="ki-outline ki-cross fs-2" />
                </span>
              )}
            </div>
          );
        })}

        <div className="mt-2 text-center">
          <button
            className="btn btn-light-primary"
            onClick={() => {
              set_metrics_filter_obj([
                ...metrics_filter_obj,
                {
                  attribute: null,
                  operator: null,
                  value: "",
                },
              ]);
            }}
          >
            <i className="ki-outline ki-plus fs-2" />
            Add another
          </button>{" "}
        </div>
      </div>
      {/* <div className="col-12 my-5 text-center"></div> */}
    </div>
  );
};

export default CampaignFilters;
